import React from "react";
import styled from "styled-components";

const Logo = () => {
  return (
    <MainContainer>
      <h1 className="banu">BANU</h1>
      <h1 className="cicek">CICEK</h1>
      <h1 className="tulu">TÜLÜ</h1>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  @media (max-width: 768px) {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    top: 0%;
    z-index: 12;
    height: 12vh;

    h1 {
      margin: 0px 0 0 3%;

      color: black;
      font-family: "Rubik Mono One", sans-serif;
      font-size: 6vw;
      color: yellow;
      filter: drop-shadow(0px 0px 2px black);
      background: -webkit-linear-gradient(45deg, #ff00e5, #ff1e00);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  @media (min-width: 769px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    top: 0%;
    z-index: 12;

    h1 {
      margin: 0px 0 0 3%;

      color: black;
      font-family: "Rubik Mono One", sans-serif;
      font-size: 1vw;
      color: yellow;
      filter: drop-shadow(0px 0px 2px black);
      background: -webkit-linear-gradient(45deg, #ff00e5, #ff1e00);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .banu {
      font-size: 1.4vw;
      /* writing-mode: vertical-rl; */
    }

    .cicek {
      font-size: 1.11vw;
      margin-top: -0.1vw;
    }

    .tulu {
      font-size: 1.4vw;
      margin-top: -0.1vw;
    }
  }
`;

export default Logo;
