import React from "react";
import styled from "styled-components";
import { Route, Link } from "react-router-dom";

const RouteB = styled(Route)`
  color: yellow;
`;

const LinkName = styled.p`
  margin: 0px;
  margin-top: 10px;

  background: -webkit-linear-gradient(45deg, #ff00e5, #ff1e00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  writing-mode: vertical-rl;
  transition: all 0.5s cubic-bezier(0, 2, 1, 0.55);
`;

const LinkCont = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: all 0.3s linear;
  &:hover .hoverLayer {
    animation: navHover 1.5s linear infinite alternate;
  }
  &:active .hoverLayer {
    background-image: linear-gradient(white, white);
  }

  &:hover .linkOrigin {
    background: -webkit-linear-gradient(45deg, #fff, #ff1e00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 30vh;
  }
`;

const NavLink = styled(Link)`
  @media (max-width: 768px) {
    text-decoration: none;
    font-family: "Rubik Mono One", sans-serif;
    font-weight: 300;
    font-size: 11.5vw;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .hoverLayer {
      width: 0vw;
      height: 0vw;
      background-image: linear-gradient(#880dff, red);
      border-radius: 50vw;
      position: absolute;
      z-index: -1;
      top: 50%;
      transform: translateY(-50%);
    }

    @keyframes navHover {
      from {
        width: 0vw;
        height: 0vw;
      }

      to {
        width: 100vw;
        height: 100vw;
      }
    }
  }
  @media (min-width: 769px) {
    text-decoration: none;
    font-family: "Rubik Mono One", sans-serif;
    font-weight: 300;
    font-size: 1.5vw;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .hoverLayer {
      width: 0vw;
      height: 0vw;
      background-image: linear-gradient(#880dff, red);
      border-radius: 50vw;
      position: absolute;
      z-index: -1;
      top: 50%;
      transform: translateY(-50%);
    }

    @keyframes navHover {
      from {
        width: 0vw;
        height: 0vw;
      }

      to {
        width: 100vw;
        height: 100vw;
      }
    }
  }
`;

const LinkOrStatic = (props) => {
  const path = props.to;
  return (
    <>
      <RouteB exact path={path}>
        {({ match }) => {
          if (match) {
            return (
              <LinkCont className={props.className}>
                <NavLink to={props.to} onClick={props.onClick} id={props.id}>
                  <LinkName className="text">{props.text}</LinkName>
                  {props.children}
                </NavLink>
              </LinkCont>
            );
          }
          return (
            <LinkCont className={props.className}>
              <NavLink
                name={props.name}
                to={props.to}
                onClick={props.onClick}
                id={props.id}
              >
                <div className="hoverLayer"></div>
                <LinkName className="linkOrigin text">{props.text}</LinkName>
              </NavLink>
            </LinkCont>
          );
        }}
      </RouteB>
    </>
  );
};
export default LinkOrStatic;
