import React from "react";
import styled from "styled-components";

const Cont = styled.ul`
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 0;

    li {
      list-style: none;
      padding: 0;
      width: 100%;
      height: auto;
      transition: all 0.5s ease-out;
      background: white;
      display: flex;
      flex-direction:column;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: 50% 50%;
        margin-bottom: .5rem;
        border: 1px solid black;
      }
    }
  }
  @media (min-width: 769px) {
    width: 100%;
    padding: 0;

    li {
      list-style: none;
      padding: 0;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(250px, 2fr));
      grid-gap: 2rem;

    
     

      img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15vw;
        height: 15vw;
        object-fit: cover;
        object-position: 50% 50%;
        
       
      }
    }
  }
`;

const ListItemImageCont = (props) => {


  return (
    <Cont className="imageCont">
      <li>
          {props.images.map((item, i)=> <img alt="Project" src={item} />)}
      </li>

    </Cont>
  );
};

export default ListItemImageCont;
