import React from "react";
import styled from "styled-components";


const Main = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 10;
    padding: 0;

    div {
      width: auto;
      padding: 0%;
      font-family: "Rubik", sans-serif;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      width: 100%;
      padding-top: 0vw;

      /* height: 90%; */
    }

    .contentRight {
      width: 100%;
      margin-left: 0vw;
      text-align: start;
      justify-content: flex-start;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      margin-top: 0%;
    }
    .image {
      position: relative;
      z-index: -2;
      width: 100%;
      height: 50vw;
      border: 0px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      z-index: -2;

      &:hover::before {
        opacity: 0.3;
      }
    }
  }
  @media (min-width: 769px) {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 10;

    div {
      width: 50%;
      padding: 0%;
      font-family: "Rubik", sans-serif;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      width: 100%;
      padding-top: 2vw;
      width: 20vw;
      /* height: 90%; */
    }

    .contentRight {
      width: 50vw;
      margin-left: 5vw;
      text-align: start;
      justify-content: flex-start;
    }

    img {
      width: 20vw;
      height: 20vw;
      object-fit: cover;
      margin-top: 0%;
    }

    .image {
      /* margin-top: 5%; */
      position: relative;
      z-index: 2;
      width: 20vw;
      height: 20vw;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover::before {
        opacity: 0.3;
      }

      &::before {
        content: "";
        width: 20vw;
        height: 20vw;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQYV2P8z8Dwn5GBgZEBCuAMDAGYSgwVALLeBAWsNw3pAAAAAElFTkSuQmCC)
          repeat;
        position: absolute;
        left: 0%;
        top: 0%;
        z-index: 1;
        opacity: 1;
        transition: all 0.3s linear;
      }
    }
  }
`;

const Div = styled.div`
  @media (max-width: 768px) {
    width: auto;
    display: flex;
  }
  @media (min-width: 769px) {
    width: 100%;
    display: flex;
  }
`;

const Ul = styled.ul`
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-top: 0%;
    margin-bottom: 2vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 18px;
    grid-row-gap: 20px;
    place-items: center;

    display: flex;
    flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
    justify-content: space-around;
    align-items: center;
    &:nth-child(n-1) {
      margin-bottom: ${(props) => (props.reverse ? "5vw" : "5vw")};
    }
  }
  @media (min-width: 769px) {
    width: 100%;
    padding: 0;
    margin-top: 0%;

    margin-bottom: 2vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 18px;
    grid-row-gap: 20px;
    place-items: center;

    display: flex;
    flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
    justify-content: space-around;
    align-items: center;
    &:nth-child(n-1) {
      margin-bottom: ${(props) => (props.reverse ? "5vw" : "5vw")};
    }
  }
`;

const H1 = styled.h1`
  @media (max-width: 768px) {
    font-size: 8vw;
    color: black;
    text-shadow: 0px 0px 2px black;
    margin: 0.5vw 0 0 2vw;
  }
  @media (min-width: 769px) {
    font-size: 4vw;
    color: black;
    text-shadow: 0px 0px 2px black;
    margin: 0.5vw 0 0 0;
  }
`;

const SectionBlueprint = (props) => {

  return (
    <Main>
      <div className="content">
        <div className="image">
          <img src={props.img} alt="pic" />
        </div>
        <Div className="header">
          <H1 primary={props.primary}>{props.title}</H1>
        </Div>
      </div>
      <div className="content contentRight">
        <Ul reverse={props.rev}>{props.children}</Ul>
      </div>
    </Main>
  );
};

export default SectionBlueprint;
