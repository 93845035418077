import React from 'react'
import styled from "styled-components";


const Button = styled.a`
    padding: 0;



      text-decoration: none;


button{
    padding: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1DA0C3;
    border-radius: .2rem;
    outline: none;
    border: 1px solid #1DA0C3;
    overflow: hidden;
    
    &:hover {
    box-shadow: 0 2px 4px grey}
   };

    .button_icon {
    height: 40px;
    width: 40px;
    border-right: 1px solid white;
    padding: 0 10px 0 10px
    };
    
    .button_title {
      font-family: "Rubik", sans-serif;
      width: 100%,;
      color: #1DA0C3;
      background: white; 
      width: 100%; 
      height: 40px;
      display: flex; 
      align-items: center; 
      padding: 0 5px 0 5px
    }
    
    `




export const BandcampButton =(props)=>{

    return <Button href={props.url} target="_blank">
                    <button>
                        <img src={props.icon} alt="icon" className="button_icon"/>
                        <div className="button_title">{props.text}</div>
                    </button>
                </Button>
}