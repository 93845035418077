import React from "react";
import styled from "styled-components";

const BgLightsBig = () => {
  return <MainContainer></MainContainer>;
};

const MainContainer = styled.div`
  width: 1000%;
  height: 100vw;
  /* transform: skew(10deg); */
  /* border-radius: 50%; */
  background-image: linear-gradient(
    90deg,
    rgba(255, 30, 0, 0.95),
    rgba(255, 0, 229, 0.4),
    red,
    magenta
  );
  position: absolute;
  left: 0%;
  bottom: 0%;
  /* filter: blur(80px); */
  animation: rotate 12s linear infinite alternate-reverse;
  @keyframes rotate {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-90%);
    }
  }
`;

export default BgLightsBig;
