import React, { Fragment, useState } from "react";
import { Context } from "./Context";

//We pass in props to the Container so we can access the children
const Container = (props) => {
  //Create state and setState functions with useState as needed
  const [navButtonHover, setNavButtonHover] = useState(false);

  const MainText = [
    "Banu Çiçek Tülü (Adana/Turkey, 1984) is an artist, researcher and DJ with a background in urban design from South-East Turkey based in Berlin. She develops her ideas and research by using sound as a primary medium and sonic methodologies. Her practice-based artistic approach involves participation, social design, ecology, feminist and queer theory which uses artistic, cultural and political imagination as tools for social change. The process of the artistic production is the crucial and it is mostly presented as multi-channel video and sound installations, sculptural elements and various objects.",
  ];
  const AboutText = [
    [
      "Banu Çiçek Tülü (Adana/Turkey, 1984) is an artist, researcher and DJ with a background in urban design from South-East Turkey based in Berlin. She develops her ideas and research by using sound as a primary medium and sonic methodologies. Her practice-based artistic approach involves participation, social design, ecology, feminist and queer theory which uses artistic, cultural and political imagination as tools for social change. The process of the artistic production is the crucial and it is mostly presented as multi-channel video and sound installations, sculptural elements and various objects.",
      "The recent fellowships include IdeasCity New Orleans/USA, an initiative of The New Museum, New York/USA (2019); Sound of Our Cities Roeselare/Belgium organized by Idensitat/Spain with the Creative Europe Program (2020); Ankara Queer Art Residency (2021) by Kaos GL (Ankara/Turkey) supported by SAHA Association (Istanbul/Turkey), Artist Residency in Namibia by Akademie Schloss Solitude (Stutgart/ Germany), Musicboard Fellowship (2021) by Musicboard Berlin and The Senate Department for Culture and Europe (Berlin/Germany). Banu Çiçek Tülü completed her MA first in University of Barcelona, Fine Arts Faculty (2011), later in the PEI Independent Study Program at Contemporary Art Museum Barcelona (2012).",
      "She has been participated group exhibitions, lead workshops and soundwalks in Die Neue Sammlung/Design Museum Munich (2019), CRISAP Tokyo Sound:Gender:Feminism:Activism (2019), alpha nova & gallery futura Berlin (2020), Kunstraum Bethanien (2021).",
      "She is currently PhD candidate at the Fine Arts University Hamburg granted by Heinrich Böll Foundation. Since 2018, she has been a lecturer at the Humboldt University in Berlin, teaching the seminars Urban Activism and Berlin Sonic: Auditory Collective Explorations. Started when she was a BA student (2003-2009) in Istanbul, she continues her political engagement also in Germany while working with different activist groups.",
    ],
  ];
  const UrbanWorks = [
    [
      "...is an art practice based thesis which focuses on sound can be used as an important element in urban design process. After covering different methods that have an artistic approach to including sound into urban design, the thesis refers to the artistic project called “Techno City.",
      "This project aims to use Techno Music Dj’ing techniques and translate the process into urban design. For the data collection, Köpenickerstrasse in Berlin has been chosen as a case study. Besides proposing hands-on methods to urban designers, the thesis invites readers to think about their sound environment.",
    ],
  ];

  const AcademicWorks = [
    [
      "Is it possible to measure space with sound? Can the repetitions help us to create alternative cartographies? If yes, how social, cultural and political rhythms shape our everyday life?",
      "Rhythmic Encounters is a 90 min. workshop and the results will be shown as a sound installation. The aim of the workshop is a sonic analysis of urban space using soundwalk method from Soundscape Studies and Lefevbre’s Rhythmanalysis. Soundwalk is a  multidisciplinary and qualitative approach through walking, in which listening becomes the primary source of information.",
      "In this active, dynamic and critical performance rhythms will guide the participants throughout the selected area(s) to discover and/or produce acoustic territories. In a game format, the participants will explore the sounds with attentive listening and audio diagramming techniques while considering their social and cultural context. A map will be provided as a score where the instructions will be stated. At the end of the workshop, the results will be collected. Besides the internal review, we will create/work on a sound installation in order to open up the discussion to the public and share this collective action. ",
    ],
  ];

  const SoundWorks = [
    [
      "It considers sidewalks and walking as a privileged act and draws the attention to the sonic violence towards women and FLTI*. One of the most common protection/isolation is using headphones. During the workshop, the participants are invited to create different headphones. They will produce/design/create several headphones using recycled material/ or used material. These headphones will be provocative rather than aesthetic.",
      "The reason is two-folded: First; to enhance the political possibility of design. Design is political and provocative, design sexualizes, design also colonized, design manipulates, design segregate. Design objects have also always come about in a political context and that in many cases there was/is a sociopolitical intention behind their development. These DIY design headphones would like to initiate a conversation with/for women and FLTI*. Second; hearing and listening are totally different notions.",
      "Working with both these senses challenge us and  can teach us the “attentive listening” (referring to Pauline Oliveros) which can have a great impact on us in terms of creating awareness, understanding different perspectives and enhancing our political imagination. Thus, soundwalks encourage us to think on politics of sound. Overall, this proposal is interested with the empowerment of women and FLTI* through sound.",
    ],
  ];

  const Projects = [


        {
          title: "TransSoundScapes",
          subTitle: "Debut Album",
          text:
            [
            "All tracks written and produced by",
            "Collaborators: Bianka, Patricia, Prince Emrah, Aérea Negrot",
            "Label: Intergalactic Research Institute for Sound",
            "Photography by George Nebieridze",
            "Artwork and layout by Irakli Kziria / Design Provocation",
            "PR and text: Andra Nikolayi / Polidoris PR",
            "Supported by Musicboard Berlin"],
          contact: [
              "*Please contact for the further information."],
        links: [
          "https://intergalacticresearchinstituteforsound.bandcamp.com/album/transsoundscapes",
          "banuct@gmail.com"

        ]}

  ]

  const Dates = [
    ["University of Arts - (UDK)", "", "Berlin", "February 2019", "157-198"],
    [
      "Ideas city / New Museum",
      "",
      "New Orleans",
      "April 2019",
      "Blocking the Sound",
    ],
    [
      "Workshop at Humboldt University",
      "Seminar Berlin Perspectives",
      "Berlin",
      "July 2019",
      "Rhythmic Encounters",
    ],
    [
      "Workshop at FoWe",
      "Conferences in Critical Geography",
      "Berlin",
      "July 2019",
      "Rhythmic Encounters",
    ],
    [
      "Die Neue Sammlung Museum",
      "",
      "Munich",
      "September 2019",
      "Blocking the Sound",
    ],
    [
      "Sound::Gender::Feminism::Activism",
      "",
      "Tokyo",
      "October 2019",
      "Blocking the Sound",
    ],
    [
      "Workshop at Skuor - TU",
      "Conferences in Critical Geography",
      "Vienna",
      "November 2019",
      "Rhythmic Encounters",
    ],
    [
      "Workshop at Humboldt University",
      "Seminar Berlin Perspectives",
      "Berlin",
      "January 2020",
      "Rhythmic Encounters",
    ],
    [
      "Installation at Alpha Nova & Galeria Future",
      "A feminist perspective for Berlin today!",
      "Berlin",
      "August-September 2020",
      "/ / / / UFO",
    ],
  ];
  return (
    //Pass the states and setState functions through the context.provider. Wrap around the children.
    <Context.Provider
      value={{
        MainText,
        AboutText,
        UrbanWorks,
        AcademicWorks,
        SoundWorks,
        Dates,
        navButtonHover,
        setNavButtonHover,
        Projects
      }}
    >
      <Fragment>{props.children}</Fragment>
    </Context.Provider>
  );
};

export default Container;
