import React from "react";
import styled from "styled-components";
import Header from "../Header/Header";
import MainSection from "../Main/MainSection";
import BlobBg from "../Structure/BlobbBg";
var w = window.innerWidth;
const MainLayer = () => {
  return (
    <MainCont>
      <ContentLayer>
        {w > 800 && <BlobBg />}

        <Header />
        <MainSection />
      </ContentLayer>
    </MainCont>
  );
};

const MainCont = styled.div`
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  background: white;
  display: flex;
  position: relative;
  border: 1px solid black;
  overflow: hidden;
`;

const ContentLayer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    display: flex;

    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  @media (min-width: 769px) {
    width: 100%;
    height: 100%;
    display: flex;

    flex: 1;
    justify-content: space-between;
    position: relative;
  }
`;

export default MainLayer;
