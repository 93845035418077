import React from "react";
import styled from "styled-components";
import NavButton from "./NavButton";

const Nav = () => {
  return (
    <MainContainer>
      <NavButton />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  @media (max-width: 768px) {
    width: 30%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  @media (min-width: 769px) {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
`;

export default Nav;
