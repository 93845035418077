import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import MainLayer from "./Structure/MainLayer";
import BgLightsBig from "./Header/BgLightBig";
import Container from "../Context/Container";
import CSSPlugin from "gsap/CSSPlugin";
import Cursor from "../Assets/Cursor/Asset 2.png";

import {
  useTransitionContextState,
  TransitionContext,
} from "./NavLogic/TransitionContext";

const MainCont = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: url(${Cursor}), auto;
`;
const C = CSSPlugin;

const App = () => {
  const transitionContext = useTransitionContextState();

  return (
    <Container>
      <TransitionContext.Provider value={transitionContext}>
        <Router>
          <MainCont>
            <BgLightsBig />

            <MainLayer />
          </MainCont>
        </Router>
      </TransitionContext.Provider>
    </Container>
  );
};

export default App;
