import React from "react";
import styled from "styled-components";

import TransitionRoute from "../NavLogic/TransitionRoute";
import Home from "../Main/Home";
import About from "../Main/About";
import Projects from "./Projects";
import Information from "../Main/Information";

const Main = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    height: calc(100% - 19vh);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    overflow: scroll;
  }
  @media (min-width: 769px) {
    width: 77%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    overflow: scroll;
  }
`;

const MainSection = () => {


  return (
    <Main>
      <TransitionRoute path="/" exact component={Home} />
      <TransitionRoute path="/about" exact component={About} />
      <TransitionRoute path="/projects" exact component={Projects} />
      <TransitionRoute path="/dates & contact" exact component={Information} />
    </Main>
  );
};

export default MainSection;
