import React from "react";
import styled from "styled-components";



const Main = styled.div`

  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  left: 0%;
  transform: rotate(180deg);
  .blobCont {
    position: absolute;
    width: 200vw;
    height: 100vh;
  }

  .blob {
    fill: red;
    stroke: black;
  }

  .blob:nth-child(1) {
    animation: move1 20s infinite linear;
  }

  @keyframes move1 {
    from {
      transform: rotate(90deg) translate(200px, 0.1px) rotate(-90deg);
    }
    to {
      transform: rotate(450deg) translate(200px, 0.1px) rotate(-450deg);
    }
  }
  .blob:nth-child(2) {
    animation: move2 20s infinite linear;
  }

  @keyframes move2 {
    from {
      transform: rotate(180deg) translate(200px, 0.1px) rotate(-180deg);
    }
    to {
      transform: rotate(540deg) translate(200px, 0.1px) rotate(-540deg);
    }
  }
  .blob:nth-child(3) {
    animation: move3 20s infinite linear;
  }

  @keyframes move3 {
    from {
      transform: rotate(270deg) translate(200px, 0.1px) rotate(-270deg);
    }
    to {
      transform: rotate(630deg) translate(200px, 0.1px) rotate(-630deg);
    }
  }
  .blob:nth-child(4) {
    animation: move4 20s infinite linear;
  }

  @keyframes move4 {
    from {
      transform: rotate(360deg) translate(200px, 0.1px) rotate(-360deg);
    }
    to {
      transform: rotate(720deg) translate(200px, 0.1px) rotate(-720deg);
    }
  }
  .blob:nth-child(5) {
    animation: move5 20s infinite linear;
  }

  @keyframes move5 {
    from {
      transform: rotate(450deg) translate(200px, 0.1px) rotate(-450deg);
    }
    to {
      transform: rotate(810deg) translate(200px, 0.1px) rotate(-810deg);
    }
  }
  .blob:nth-child(6) {
    animation: move6 20s infinite linear;
  }

  @keyframes move6 {
    from {
      transform: rotate(540deg) translate(200px, 0.1px) rotate(-540deg);
    }
    to {
      transform: rotate(900deg) translate(200px, 0.1px) rotate(-900deg);
    }
  }
  .blob:nth-child(7) {
    animation: move7 20s infinite linear;
  }

  @keyframes move7 {
    from {
      transform: rotate(630deg) translate(200px, 0.1px) rotate(-630deg);
    }
    to {
      transform: rotate(990deg) translate(200px, 0.1px) rotate(-990deg);
    }
  }
  .blob:nth-child(8) {
    animation: move8 20s infinite linear;
  }

  @keyframes move8 {
    from {
      transform: rotate(720deg) translate(200px, 0.1px) rotate(-720deg);
    }
    to {
      transform: rotate(1080deg) translate(200px, 0.1px) rotate(-1080deg);
    }
  }
  .blob:nth-child(9) {
    animation: move9 20s infinite linear;
  }

  @keyframes move9 {
    from {
      transform: rotate(810deg) translate(200px, 0.1px) rotate(-810deg);
    }
    to {
      transform: rotate(1170deg) translate(200px, 0.1px) rotate(-1170deg);
    }
  }
  .blob:nth-child(10) {
    animation: move10 20s infinite linear;
  }

  @keyframes move10 {
    from {
      transform: rotate(900deg) translate(200px, 0.1px) rotate(-900deg);
    }
    to {
      transform: rotate(1260deg) translate(200px, 0.1px) rotate(-1260deg);
    }
  }
  .blob:nth-child(11) {
    animation: move11 20s infinite linear;
  }

  @keyframes move11 {
    from {
      transform: rotate(990deg) translate(200px, 0.1px) rotate(-990deg);
    }
    to {
      transform: rotate(1350deg) translate(200px, 0.1px) rotate(-1350deg);
    }
  }
  .blob:nth-child(12) {
    animation: move12 20s infinite linear;
  }

  @keyframes move12 {
    from {
      transform: rotate(1080deg) translate(200px, 0.1px) rotate(-1080deg);
    }
    to {
      transform: rotate(1440deg) translate(200px, 0.1px) rotate(-1440deg);
    }
  }
  .blob:nth-child(13) {
    animation: move13 20s infinite linear;
  }

  @keyframes move13 {
    from {
      transform: rotate(1170deg) translate(200px, 0.1px) rotate(-1170deg);
    }
    to {
      transform: rotate(1530deg) translate(200px, 0.1px) rotate(-1530deg);
    }
  }
  .blob:nth-child(14) {
    animation: move14 20s infinite linear;
  }

  @keyframes move14 {
    from {
      transform: rotate(1260deg) translate(200px, 0.1px) rotate(-1260deg);
    }
    to {
      transform: rotate(1620deg) translate(200px, 0.1px) rotate(-1620deg);
    }
  }
  .blob:nth-child(15) {
    animation: move15 20s infinite linear;
  }

  @keyframes move15 {
    from {
      transform: rotate(1350deg) translate(200px, 0.1px) rotate(-1350deg);
    }
    to {
      transform: rotate(1710deg) translate(200px, 0.1px) rotate(-1710deg);
    }
  }
  .blob:nth-child(16) {
    animation: move16 20s infinite linear;
  }

  @keyframes move16 {
    from {
      transform: rotate(1440deg) translate(200px, 0.1px) rotate(-1440deg);
    }
    to {
      transform: rotate(1800deg) translate(200px, 0.1px) rotate(-1800deg);
    }
  }
  .blob:nth-child(17) {
    animation: move17 20s infinite linear;
  }

  @keyframes move17 {
    from {
      transform: rotate(1530deg) translate(200px, 0.1px) rotate(-1530deg);
    }
    to {
      transform: rotate(1890deg) translate(200px, 0.1px) rotate(-1890deg);
    }
  }
  .blob:nth-child(18) {
    animation: move18 20s infinite linear;
  }

  @keyframes move18 {
    from {
      transform: rotate(1620deg) translate(200px, 0.1px) rotate(-1620deg);
    }
    to {
      transform: rotate(1980deg) translate(200px, 0.1px) rotate(-1980deg);
    }
  }
`;

const BlobBg = () => {

  return (
    <Main>
      <div class="bgImage"></div>
      <svg class="blobCont">
        <image
          xlinkHref="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTL-9JGT2xMgk6CAGRQ-lFO6fvIxyGtDV91gA&usqp=CAU"
          mask="url(#mask)"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
        />
        <defs>
          <filter id="gooey" height="130%">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="15"
              result="blur"
            />

          </filter>
        </defs>
        <mask id="mask" x="0" y="0">
          <g style={{ filter: "url(#gooey)" }}>
            <circle
              class="blob"
              cx="90%"
              cy="20%"
              r="120"
              fill="white"
              stroke="white"
            />

            <circle
              class="blob"
              cx="90%"
              cy="60%"
              r="90"
              fill="white"
              stroke="white"
            />

            <circle
              class="blob"
              cx="17%"
              cy="15%"
              r="70"
              fill="white"
              stroke="white"
            />

            <circle
              class="blob"
              cx="30%"
              cy="25%"
              r="30"
              fill="white"
              stroke="white"
            />

            {/* <circle
              class="blob"
              cx="40%"
              cy="60%"
              r="90"
              fill="white"
              stroke="white"
            /> */}
          </g>
        </mask>
      </svg>
    </Main>
  );
};

export default BlobBg;
