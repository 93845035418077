import React from 'react'
import styled from "styled-components";


const Button = styled.a`
    padding: 0;
text-decoration: none;


    button{
        padding: 0px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #B096E0;
        border-radius: .2rem;
        outline: none;
        border: 1px solid #1DA0C3;
        overflow: hidden;
        
        &:hover {
        box-shadow: 0 2px 4px grey}
   };

    i {
    width: 40px;
    padding: 0 10px 0 10px;
    font-size: 1.2rem;
    color: white
    };
    
    .button_title {
      font-family: "Rubik", sans-serif;
      color: #1DA0C3;
      background: white; 
      width: 100%; 
      height: 40px;
      display: flex; 
      align-items: center; 
      padding: 0 5px 0 15px
    }
    
    `



export const DownloadButton =(props)=>{

    return <Button href={props.downloadFile} target="_blank">
        <button>
            <i className="fa fa-download"></i>
            <div className="button_title">{props.text}</div>
        </button>
    </Button>
}