import React, {  useState } from "react";
import styled from "styled-components";

const Li = styled.li`
  @media (max-width: 768px) {
    list-style: none;
    margin: 0;
    width: 95%;
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    z-index: 11;
    background-image: linear-gradient(white, white, pink);

    &:hover h2 {
      animation: blinkHoverWork 0.5s linear infinite;
    }
    &:nth-child(n) {
      border-top: 1px solid black;
    }
    &:nth-child(1) {
      margin-top: 5vw;
    }

    .column {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
    }

    h2 {
      text-transform: uppercase;
      font-size: 5.2vw;
      margin: 1vw 0 0 0;
      text-shadow: 0px 0px 2px black;
    }

    h4 {
      font-size: 3.2vw;
      margin: 0.5vw 0;
      text-shadow: 0px 0px 2px black;
      font-weight: 300;
    }

    ul {
      margin: 3vw 0;
      padding: 0;

    }
    li {
      font-family: "Rubik", sans-serif;
      font-size: 4.2vw;
      text-align: justify;
      width: 90%;
      margin: .5rem 0 .5rem 0;
      text-shadow: 0px 0px 2px black;
      list-style: none;
    }

    .center {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
    }

    .num {
      color: black;
      position: absolute;
      font-size: 2vw;
      right: 0;
      top: 1%;
      font-family: "Rubik", sans-serif;
      font-weight: 700;
      border-bottom: 1px solid black;
    }

    .imageCont {
      width: 100%;

      &::before {
        content: "";
        border-radius: 50%;
        filter: blur(20px) opacity(0.4);
        width: 40vw;
        height: 40vw;
        background-image: linear-gradient(45deg, #ff00e5, #ff1e00);
        position: absolute;
        z-index: -1;
        transform: translate(50%);
        left: -50%;
        top: 0%;
      }
    }
    
    .buttons_cont {
    width: 100%;
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;

    a {
    margin-bottom: .3rem
    }}

    @keyframes blinkHoverWork {
      0% {
        color: black;
      }

      50% {
        color: white;
      }

      100% {
        color: black;
      }
    }
  }
  @media (min-width: 769px) {
    list-style: none;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    z-index: 11;
    background: white;
    background-image: linear-gradient(white, white, pink);

    &:hover h2 {
      animation: blinkHoverWork 0.5s linear infinite;
    }
    &:nth-child(n) {
      border-top: 1px solid black;
    }

    &:nth-child(n) {
      margin-top: 0vw;
    }

    .column {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
    }

    h2 {
      text-transform: uppercase;
      font-size: 2.2vw;
      margin: 1vw 0 0 0;
      text-shadow: 0px 0px 2px black;
    }

    h4 {
      font-size: 1.2vw;
      margin: 0.5vw 0;
      text-shadow: 0px 0px 2px black;
      font-weight: 300;
    }

    ul {
      margin: 3vw 0;
      padding: 0;
    }
    li {
      font-family: "Rubik", sans-serif;
      font-size: 1.2vw;
      text-align: justify;
      width: 80%;
      margin: 1vw 0 1vw 0;
      text-shadow: 0px 0px 2px black;
      list-style: none;
    }

    .center {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
    }
    
    .left-aligned {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      position: relative;

    }

    .num {
      color: black;
      position: absolute;
      font-size: 1vw;
      right: 0;
      top: 1%;
      font-family: "Rubik", sans-serif;
      font-weight: 700;
      border-bottom: 1px solid black;
    }

    .imageCont {
      width: 100%;

      &::before {
        content: "";
        border-radius: 50%;
        filter: blur(20px) opacity(0.4);
        width: 40vw;
        height: 40vw;
        background-image: linear-gradient(45deg, #ff00e5, #ff1e00);
        position: absolute;
        z-index: -1;
        transform: translate(50%);
        left: -50%;
        top: 0%;
      }
    }
    
      .buttons_cont {
        width: 100%;    
        display: flex; 
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
    
        a {
                margin: 0.5rem
        }}

    @keyframes blinkHoverWork {
      0% {
        color: black;
      }

      50% {
        color: white;
      }

      100% {
        color: black;
      }
    }
  }
`;

const List = (props) => {


  const [openItem, setOpenItem] = useState(false);

  return (
    <Li onClick={() => setOpenItem(!openItem)}>
      <span className="column">
        <h2>{props.title}</h2>
        <h4>{props.subTitle}</h4>
        <span className="num">{props.num}</span>
      </span>
      <span className="center">
        {openItem && (
          <ul className="left-aligned">
            {props.array.map((item, i) => {

              return <li key={i}>{item}</li>;
            })}
              <div className="buttons_cont">{props.buttons}</div>
              {props.contact && props.contact.map((item, i) => <li key={i}>{item}</li>)}

              <div className="imageCont">{props.children}</div>

          </ul>
        )}
      </span>
    </Li>
  );
};

export default List;
