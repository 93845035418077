import React, { useContext } from "react";
import { Context } from "../../Context/Context";
import SectionBlueprint from "./SectionBlueprint";
import List from "./List";
import ImageOne from "../../Assets/Photos/Academic/academic.jpg";

import ImgOne from "../../Assets/Photos/TransSoundScapes/IRIS011_cover_front_fit.jpg";
import ImgTwo from "../../Assets/Photos/TransSoundScapes/IRIS011_cover_Back_new.jpg";
import PressKit from "../../Assets/Photos/TransSoundScapes/Press_release_Banu_TransSoundScapes_LP_fin.pdf"

import ListItemImageCont from "./ListItemImageCont";
import {BandcampButton} from "../Diverse/IconButtons/BandcampButton";
import Icon from "../../Assets/Icons/bandcamp-button-square-aqua-64.png";
import {EmailButton} from "../Diverse/IconButtons/EmailButton";
import {DownloadButton} from "../Diverse/IconButtons/DownloadButton";



const Projects = () => {
  const context = useContext(Context);

  return (
    <>
      <SectionBlueprint img={ImageOne} title="PROJECTS" primary="true">
       <List
          title={context.Projects[0].title}
          subTitle={context.Projects[0].subTitle}
          num="01"
          array={context.Projects[0].text}
          contact={context.Projects[0].contact}
          buttons={<>
              <BandcampButton text="Buy at Bandcamp" icon={Icon} url={context.Projects[0].links[0]}/>
              <EmailButton text="Write a Mail" icon={Icon} />
              <DownloadButton text="Press kit" downloadFile={PressKit}/></>}
        >

           <ListItemImageCont
               images={[ImgOne, ImgTwo]}


          />
        </List>


      </SectionBlueprint>
    </>
  );
};

export default Projects;
