import React, { useContext } from "react";
import styled from "styled-components";
import { Context } from "../../Context/Context";
import SectionBlueprint from "./SectionBlueprint";
import ImageOne from "../../Assets/Photos/WORKSHOP_PINAKOTHEK_ESL4492_lrg.jpg";

const Li = styled.li`
  @media (max-width: 768px) {
    list-style: none;
    margin: 0;
    width: 100%;
    height: 90%;
    border-bottom: 0px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    z-index: 11;
    &:hover h2 {
      animation: blinkHoverWork 0.5s linear infinite;
    }
    &:nth-child(1) {
      border-top: 0px solid black;
    }

    .column {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
    }

    h2 {
      text-transform: uppercase;
      font-size: 8vw;
      margin: 0vw 0 0vw 2vw;
      text-shadow: 0px 0px 2px black;
    }

    h4 {
      font-size: 4vw;
      margin: 0vw 0 0vw 2vw;
      text-shadow: 0px 0px 2px black;
      font-weight: 300;
    }

    p {
      font-family: "Rubik", sans-serif;
      font-size: 0.8vw;
      text-align: justify;
      width: 80%;
      margin: 2vw 0;
      text-shadow: 0px 0px 2px black;
    }

    ul {
      margin: 0vw 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    li {
      font-family: "Rubik", sans-serif;
      font-size: 3.2vw;
      text-align: justify;
      width: 80%;
      margin: 1vw 0 1vw 0;
      text-shadow: 0px 0px 2px black;
      list-style: none;
    }

    .imageCont {
      width: 100%;

      &::before {
        content: "";
        border-radius: 50%;
        filter: blur(20px) opacity(0.4);
        width: 40vw;
        height: 40vw;
        background-image: linear-gradient(45deg, #ff00e5, #ff1e00);
        position: absolute;
        z-index: -1;
        transform: translate(50%);
        left: -50%;
        top: 0%;
      }
    }
  }
  @media (min-width: 769px) {
    list-style: none;
    margin: 0;
    width: 100%;
    height: 90%;
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    z-index: 11;
    &:hover h2 {
      animation: blinkHoverWork 0.5s linear infinite;
    }
    &:nth-child(1) {
      border-top: 1px solid black;
    }

    .column {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
    }

    h2 {
      text-transform: uppercase;
      font-size: 2vw;
      margin: 1vw 0 0 0;
      text-shadow: 0px 0px 2px black;
    }

    h4 {
      font-size: 0.8vw;
      margin: 0.5vw 0;
      text-shadow: 0px 0px 2px black;
      font-weight: 300;
    }

    p {
      font-family: "Rubik", sans-serif;
      font-size: 0.8vw;
      text-align: justify;
      width: 80%;
      margin: 2vw 0;
      text-shadow: 0px 0px 2px black;
    }

    ul {
      margin: 3vw 0;
    }
    li {
      font-family: "Rubik", sans-serif;
      font-size: 1.2vw;
      text-align: justify;
      width: 80%;
      margin: 1vw 0 1vw 0;
      text-shadow: 0px 0px 2px black;
      list-style: none;
    }

    .imageCont {
      width: 100%;

      &::before {
        content: "";
        border-radius: 50%;
        filter: blur(20px) opacity(0.4);
        width: 40vw;
        height: 40vw;
        background-image: linear-gradient(45deg, #ff00e5, #ff1e00);
        position: absolute;
        z-index: -1;
        transform: translate(50%);
        left: -50%;
        top: 0%;
      }
    }

    @keyframes blinkHoverWork {
      0% {
        color: black;
      }

      50% {
        color: white;
      }

      100% {
        color: black;
      }
    }
  }
`;

const About = () => {
  const context = useContext(Context);

  return (
    <>
      <SectionBlueprint img={ImageOne} title="ABOUT">

        <Li>


          <ul className=" ">
            {context.AboutText[0].map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </Li>
      </SectionBlueprint>
    </>
  );
};

export default About;
